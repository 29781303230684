const routes = {
  login: '/',
  remind: '/remind',
  dashboard: '/dashboard',
  purchase: '/purchase',
  inputEmail: '/purchase/email',
  sendConfirmEmail: '/purchase/sendMail',
  link: '/link/:id',
  passwordReset: '/password/reset/:id',
  passwordExpiredChange: '/password/expired',

  muserCreate: '/muser/create',
  muserEdit: '/muser/edit/:id',
  muserSearch: '/muser/search',

  userCreate: '/user/create',
  userEdit: '/user/edit/:id',
  userSearch: '/user/search',
  userImport: '/user/import',
  changePassword: '/user/change_password',

  groupSearch: '/group/search',
  groupCreate: '/group/create',
  groupEdit: '/group/edit/:id',
  passwordPolicy: '/group/password_policy/:id',

  licenseSearch: '/license/search',
  licenseMailEndDateTrial10Day: '/license/mailEndDateTrial10Day',
  licenseMailEndDate60Day: '/license/mailEndDate60Day',
  licenseMailPayment: '/license/mailPayment',
  stripeTransactions: '/license/transactions',
  stripeTransactionsDetail: '/license/transactions/:customerEmail',
  licenseCreate: '/license/create',
  licenseEdit: '/license/edit/:id',
  licenseUser: '/license/user',
  licenseDevice: '/license/device',
  licenseImport: '/license/import',
  userLicenseImport: '/license/user/import',
  buyerInformation: '/purchase/informationBuyer/:id',
  trialInformation: '/purchase/informationTrial/:id',
  buyLicense: '/purchase/buyLicense/:userId',
  updateStop: '/purchase/updateStop/:userId',
  startLicense: '/purchase/startLicense/:userId',
  reopenLicense: '/purchase/reopenLicenses/:userId',
  buyAdditionalLicenses: '/purchase/buyAdditionalLicenses/:userId',
  successBuyerInformation: '/purchase/buySuccess/info/:id',
  successTrialInformation: '/purchase/trial/info/:id',
  confirmInformation: '/purchase/confirmInfo',
  infoBuyerNotify: '/purchase/RegisterNotify/:id',
  AddBuyerNotify: '/purchase/addRegisterNotify/:id',
  versionCreate: '/version/create',
  versionHistory: '/version/history',
  versionEdit: '/version/edit/:id',
  userinfoReadMessage: '/userinfo/UserinfoReadMessage',
  pageNotFound: '/404',
  eventSettingTrial: '/event/trial',
  buyTrialConfirm: '/purchase/trialConfirm/:userId',
  addLicenseConfirm: '/purchase/addLicenseConfirm/:userId',
  notFound: '*',
  updateBankTransfer: '/purchase/updateBankTransfer/:userId',
  confirmUpdateBank: '/purchase/confirmUpdateBank/:userId',
  expiredPage: '/expiredPage',
  changePaymentMethod: '/purchase/changePaymentMethod/:userId',
  changePaymentMethodSuccess: '/purchase/changePaymentMethodSuccess/:userId',
  adminLogin: '/login',
};
export default routes;
