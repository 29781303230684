export const types = {
  GET_TRIAL_SETTING: 'GET_TRIAL_SETTING',
  GET_TRIAL_SETTING_SUCCESS: 'GET_TRIAL_SETTING_SUCCESS',
  SAVE_TRIAL_SETTING: 'SAVE_TRIAL_SETTING',
  SAVE_TRIAL_SETTING_SUCCESS: 'SAVE_TRIAL_SETTING_SUCCESS',
  GET_SYNC_SETTING: 'GET_SYNC_SETTING',
  GET_SYNC_SETTING_SUCCESS: 'GET_SYNC_SETTING_SUCCESS',
};

export const getTrialSetting = (payload: any) => ({
  type: types.GET_TRIAL_SETTING,
  payload,
});

export const getTrialSettingSuccess = (payload: any) => ({
  type: types.GET_TRIAL_SETTING_SUCCESS,
  payload,
});

export const saveTrialFlag = (payload: boolean) => ({
  type: types.SAVE_TRIAL_SETTING,
  payload,
});

export const saveTrialFlagSuccess = (payload: boolean) => ({
  type: types.SAVE_TRIAL_SETTING_SUCCESS,
  payload,
});

export const getSyncSetting = (payload: any) => ({
  type: types.GET_SYNC_SETTING,
  payload,
});

export const getSyncSettingSuccess = (payload: any) => ({
  type: types.GET_SYNC_SETTING_SUCCESS,
  payload,
});
