import moment from 'moment';
import styled from '@emotion/styled';
import { DEFAULT_PAGE } from '../../../constants/app';
import { TableColumn } from '../../../types';

export type RegisterInput = {
  groupId: string | null;
  licenseCode: string;
  purchaseType: string;
  availableDeviceNumber: number;
  startDate: string;
  endDate: string;
  isInvalid: boolean;
  comment: string;
};

export type Column = TableColumn & {
  id:
    | 'no'
    | 'customerEmail'
    | 'amount'
    | 'status'
    | 'action'
    | 'detail'
};

export const columns: Column[] = [
  {
    id: 'no',
    label: 'No.',
    align: 'center',
  },
  {
    id: 'customerEmail',
    label: 'common.label.email',
  },
  {
    id: 'amount',
    label: 'stripeTransactions.label.amount',
    align: 'center',
  },
  {
    id: 'status',
    label: 'stripeTransactions.label.status',
    align: 'center',
  },
  {
    id: 'action',
    label: 'common.label.invalid',
    align: 'center',
  },
  {
    id: 'detail',
    label: 'stripeTransactions.label.detail',
    align: 'center',
  },
];

export type ColumnDetail = TableColumn & {
  id:
    | 'no'
    | 'paymentId'
    | 'customerId'
    | 'customerEmail'
    | 'paymentMethod'
    | 'amount'
    | 'status'
    | 'createdAt'
};

export const columnsDetail: ColumnDetail[] = [
  {
    id: 'no',
    label: 'No.',
    align: 'center',
  },
  {
    id: 'paymentId',
    label: 'stripeTransactions.label.paymentId',
  },
  {
    id: 'customerId',
    label: 'stripeTransactions.label.customerId',
  },
  {
    id: 'customerEmail',
    label: 'common.label.email',
  },
  {
    id: 'paymentMethod',
    label: 'stripeTransactions.label.paymnentMethod',
  },
  {
    id: 'amount',
    label: 'stripeTransactions.label.amount',
    align: 'center',
  },
  {
    id: 'createdAt',
    label: 'stripeTransactions.label.date',
    format: (value: Date) => moment.utc(value).format('YYYY/MM/DD'),
    align: 'center',
  },
  {
    id: 'status',
    label: 'stripeTransactions.label.status',
    align: 'center',
  },
];

export type SearchCriteriaInput = {
  isList: boolean | true;
  customerEmail: string | null;
  status: string | null;
  pageIndex: number;
};

export const SearchCriteriaDefaultInput: SearchCriteriaInput = {
  isList: true,
  customerEmail: '',
  status: '',
  pageIndex: DEFAULT_PAGE,
};

interface MainProps {
  status: 'succeeded' | 'requires_action' | 'canceled' | 'processing' | 'requires_capture' | 'requires_confirmation' | 'requires_payment_method';
}

export const StyledTableCell = styled('div')<MainProps>(({ status }) => ({
  fontSize: 14,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  gap: '4px',
  padding: '10px',
  borderRadius: '5px',
  fontWeight: 'bold',
  color: status === 'succeeded' ? '#217005' : '#596171',
  backgroundColor: status === 'succeeded' ? '#d1fab3' : '#ebeef1',
}));
