/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable max-len */
import { ReactNode, useEffect } from 'react';
import { useSelector, RootStateOrAny, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { UnpackNestedValue, useForm } from 'react-hook-form';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { IconCheck, IconClock, IconX } from '@tabler/icons';
import { start } from '../../../lib/saga-promise';
import roles from '../../../constants/roles';
import SearchForm from '../../../component/Layout/SearchForm';
import SearchResult from '../../../component/Layout/SearchResult';
import { ColumnDetail, columnsDetail, SearchCriteriaDefaultInput, SearchCriteriaInput, StyledTableCell } from './types';
import TGGrid from '../../../component/Elements/TGGrid';
import { searchTransactions } from '../../../state/actions/license';
import OverHideText from '../../../component/Elements/OverHideText';
import { SIZE_PAGE } from '../../../constants/app';


export default function StripePaymentTransactionDetail() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const param = useParams();

  const { handleSubmit, setValue, getValues } = useForm<SearchCriteriaInput>({
    defaultValues: SearchCriteriaDefaultInput,
  });

  const rows = useSelector((state: RootStateOrAny) => state.license);
  const user = useSelector((state: RootStateOrAny) => state.auth.user);
  useEffect(() => {
    handleSubmit(searchSubmit)();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const searchSubmit = async (data: UnpackNestedValue<SearchCriteriaInput>) => {
    const dataSearch = {
      ...data,
      isList: false,
      customerEmail: param.customerEmail,
    };
    await start(searchTransactions, dataSearch, dispatch);
  };

  const pageChange = (_: any, page: number) => {
    setValue('pageIndex', page);
    handleSubmit(searchSubmit)();
  };

  interface StatusTransaction {
    value: string ;
    label: string;
  }

  const statusTransaction: StatusTransaction[] = [
    {
      value: 'succeeded',
      label: 'stripeTransactions.label.succeeded',
    },
    {
      value: 'incomplete',
      label: 'stripeTransactions.label.incomplete',
    },
    {
      value: 'canceled',
      label: 'stripeTransactions.label.canceled',
    },
  ];

  return (
    <SearchForm title={t('stripeTransactions.label.top')} description={t('stripeTransactions.label.description')}>
      <TGGrid
        userRoleId={user.roleId}
        showRoles={[roles.systemAdmin]}
      >
        <SearchResult<ColumnDetail>
          maxHeight={600}
          totalCount={rows.total}
          page={getValues('pageIndex')}
          columns={columnsDetail}
          rows={rows?.transactions}
          handlePageChange={pageChange}
          tableCell={(row: any, rowKey: number, column: ColumnDetail): ReactNode => {
            const value = row[column.id];
            switch (column.id) {
              case 'no':
                return rowKey + 1 + (getValues('pageIndex') - 1) * SIZE_PAGE;
              case 'paymentId':
                return `${value}`;
              case 'paymentMethod':
                return t(`stripeTransactions.label.${value}`);
              case 'amount':
                return value ? `${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}` : '';
              case 'createdAt':
                return moment.utc(row.createdAt).format('YYYY/MM/DD');
              case 'status':
                return (
                  <StyledTableCell status={row.status}>
                    {t(
                      statusTransaction.find((item) => item.value === row.status)?.label || ''
                    )}
                    {row.status === 'succeeded' && (
                    <IconCheck size="1.3rem" />
                    )}
                    {row.status === 'canceled' && (
                    <IconX size="1.3rem" />
                    )}
                    {row.status !== 'succeeded' && row.status !== 'canceled' && (
                    <IconClock size="1.3rem" />
                    )}
                  </StyledTableCell>
                );
              default:
                return column.format ? column.format(value) : <OverHideText>{value}</OverHideText>;
            }
          }}
        />
      </TGGrid>
    </SearchForm>
  );
}
