import React from 'react';

interface SwitchProps {
  isActive: boolean; // Trạng thái bật/tắt
  onToggle: () => void; // Hàm xử lý khi người dùng nhấn vào
}

const styles: { [key: string]: React.CSSProperties } = {
  switch: {
    width: '50px',
    height: '25px',
    borderRadius: '25px',
    position: 'relative',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    transition: 'background-color 0.3s',
  },
  knob: {
    width: '21px',
    height: '21px',
    borderRadius: '50%',
    backgroundColor: '#fff',
    position: 'absolute',
    top: '2px',
    left: '2px',
    transition: 'transform 0.3s',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
  },
};

// eslint-disable-next-line react/function-component-definition
const Switch: React.FC<SwitchProps> = ({ isActive, onToggle }) => (
  // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
  <div
    style={{
      ...styles.switch,
      backgroundColor: isActive ? '#4caf50' : '#ccc',
    }}
    onClick={onToggle}
  >
    <div
      style={{
        ...styles.knob,
        transform: isActive ? 'translateX(26px)' : 'translateX(0)',
      }}
    />
  </div>
);

export default Switch;
