import { axiosClient } from './config';

class SettingServices {
  static getTrialFlag() {
    const url = 'SystemSetting/trial';
    const res = axiosClient.get(url);
    return res;
  }

  static saveTrialFlag(params: any) {
    const url = 'SystemSetting/trial';
    const res = axiosClient.post(url, params);
    return res;
  }

  static getSyncSetting() {
    const url = 'SystemSetting/sync';
    const res = axiosClient.get(url);
    return res;
  }
}

export default SettingServices;
