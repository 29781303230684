/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable max-len */
import { ReactNode, useEffect, useState } from 'react';
import { useSelector, RootStateOrAny, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { SubmitHandler, UnpackNestedValue, useForm } from 'react-hook-form';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import Sync from '@mui/icons-material/Sync';
import { Link } from 'react-router-dom';
import { IconCheck, IconClock, IconX } from '@tabler/icons';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { DEFAULT_PAGE, SIZE_PAGE } from '../../../constants/app';
import { start } from '../../../lib/saga-promise';
import roles from '../../../constants/roles';
import SearchCriteria from '../../../component/Layout/SearchCriteria';
import SearchForm from '../../../component/Layout/SearchForm';
import SearchResult from '../../../component/Layout/SearchResult';
import { Column, columns, SearchCriteriaDefaultInput, SearchCriteriaInput, StyledTableCell } from './types';
import TGGrid from '../../../component/Elements/TGGrid';
import { actionInvalidUser, searchTransactions, syncStripePaymentTransaction } from '../../../state/actions/license';
import OverHideText from '../../../component/Elements/OverHideText';
import TGTextField from '../../../component/Elements/TGTextField';
import routes from '../../../constants/routes';
import TransactionSelector from '../../../component/Form/TransactionSelector';
import Switch from '../../../component/Form/SwitchButton';
import { getSyncSetting } from '../../../state/actions/setting';

const localStorageKey = 'searchTransactions';

export default function StripePaymentTransaction() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isSync: boolean = useSelector((state: RootStateOrAny) => state.setting.isSync);
  const [customerEmail, setCustomerEmail] = useState<string | null>(null);
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [openAction, setOpenAction] = useState(false);
  const handleOpenAction = (email: string) => {
    setCustomerEmail(email);

    setOpenAction(true);
  };

  const handleCloseAction = () => {
    setOpenAction(false);
  };

  useEffect(() => {
    start(getSyncSetting, { noLoading: true }, dispatch);
  }, [dispatch]);

  const { register, handleSubmit, setValue, reset, getValues, watch } = useForm<SearchCriteriaInput>({
    defaultValues: SearchCriteriaDefaultInput,
  });

  const rows = useSelector((state: RootStateOrAny) => state.license);
  const user = useSelector((state: RootStateOrAny) => state.auth.user);

  useEffect(() => {
    setValueFromLocalStorage();
    handleSubmit(searchSubmit)();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setValueFromLocalStorage = () => {
    const searchLocalStorage = localStorage.getItem(localStorageKey);
    if (searchLocalStorage) {
      reset(JSON.parse(searchLocalStorage) as SearchCriteriaInput);
    }
  };

  const handleSearch = () => {
    setValue('pageIndex', DEFAULT_PAGE);
    handleSubmit(searchSubmit)();
  };

  const searchSubmit = async (data: UnpackNestedValue<SearchCriteriaInput>) => {
    const dataSearch = {
      ...data,
      isList: true,
    };
    await start(searchTransactions, dataSearch, dispatch);
    setItemLocalStorage({ ...data });
  };

  const setItemLocalStorage = (params: SearchCriteriaInput) => {
    localStorage.setItem(localStorageKey, JSON.stringify(params));
  };

  const pageChange = (_: any, page: number) => {
    setValue('pageIndex', page);
    handleSubmit(searchSubmit)();
  };


  interface StatusTransaction {
    value: string ;
    label: string;
  }

  const statusTransaction: StatusTransaction[] = [
    {
      value: 'all',
      label: 'searchResult.label.all',
    },
    {
      value: 'succeeded',
      label: 'stripeTransactions.label.succeeded',
    },
    {
      value: 'incomplete',
      label: 'stripeTransactions.label.incomplete',
    },
  ];

  const handleSync: SubmitHandler<any> = async () => {
    handleClose();
    try {
      await start(syncStripePaymentTransaction, null, dispatch);
      window.location.reload();
    // eslint-disable-next-line no-empty
    } catch (error: any) {
    }
  };

  const handleAction: SubmitHandler<any> = async () => {
    handleClose();
    try {
      if (customerEmail) {
        await start(actionInvalidUser, { customerEmail }, dispatch);
        window.location.reload();
      }
    // eslint-disable-next-line no-empty
    } catch (error: any) {
    }
  };

  return (
    <SearchForm title={t('stripeTransactions.label.top')} description={t('stripeTransactions.label.description')}>
      <TGGrid sx={{ mb: 2, mt: 2 }} userRoleId={user.roleId} showRoles={[roles.systemAdmin]}>
        <SearchCriteria
          handleRefresh={() => {
            reset(SearchCriteriaDefaultInput);
            const searchLocalStorage = localStorage.getItem(localStorageKey);
            if (searchLocalStorage) {
              localStorage.removeItem(localStorageKey);
            }
          }}
          handleSearch={handleSearch}
        >
          <TGGrid item xs={12} sm={3}>
            <TGTextField registration={register('customerEmail')} label={t('common.label.email')} />
          </TGGrid>
          <TGGrid item xs={12} sm={3}>
            <TransactionSelector
              listStatus={statusTransaction}
              handleChange={(event) => setValue('status', event.target.value)}
              status={watch('status')}
              hasBlank={false}
            />
          </TGGrid>
        </SearchCriteria>
      </TGGrid>
      {
        (isSync === false) && (
          <TGGrid
            item
            xs={12}
            sx={{ float: 'right', mb: 2 }}
            userRoleId={user.roleId}
            showRoles={[roles.systemAdmin]}
          >
            <Button
              onClick={handleClickOpen}
              variant="contained"
              color="primary"
              sx={{ ml: 1 }}
              startIcon={<Sync />}
            >
              {t('stripeTransactions.label.sync')}
            </Button>
          </TGGrid>
        )
      }


      <TGGrid
        userRoleId={user.roleId}
        showRoles={[roles.systemAdmin]}
      >
        <SearchResult<Column>
          totalCount={rows.total}
          page={getValues('pageIndex')}
          columns={columns}
          rows={rows?.transactions}
          handlePageChange={pageChange}
          tableCell={(row: any, rowKey: number, column: Column): ReactNode => {
            const value = row[column.id];
            switch (column.id) {
              case 'no':
                return rowKey + 1 + (getValues('pageIndex') - 1) * SIZE_PAGE;
              case 'customerEmail':
                return `${value}`;
              case 'amount':
                return value ? `${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}` : '';
              case 'status':
                return (
                  <StyledTableCell status={row.status}>
                    {t(
                      statusTransaction.find((item) => item.value === row.status)?.label || ''
                    )}
                    {row.status === 'succeeded' && (
                      <IconCheck size="1.3rem" />
                    )}
                    {row.status === 'canceled' && (
                      <IconX size="1.3rem" />
                    )}
                    {row.status !== 'succeeded' && row.status !== 'canceled' && (
                      <IconClock size="1.3rem" />
                    )}
                  </StyledTableCell>
                );
              case 'action':
                // eslint-disable-next-line no-case-declarations
                let content = null;

                if (row.status !== 'succeeded' && row.isHasUser) {
                  if (row.isInvalid) {
                    content = <LockOutlinedIcon color="error" />;
                  } else {
                    content = <Switch isActive={row.isInvalid} onToggle={() => handleOpenAction(row.customerEmail)} />;
                  }
                }

                return (
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {content}
                  </div>
                );

              case 'detail':
                return (
                  <Link to={routes.stripeTransactionsDetail.replace(':customerEmail', encodeURIComponent(row.customerEmail))}>
                    <OverHideText>{t('stripeTransactions.label.detail')}</OverHideText>
                  </Link>
                );
              default:
                return column.format ? column.format(value) : <OverHideText>{value}</OverHideText>;
            }
          }}
        />
      </TGGrid>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <DialogContentText>{t('stripeTransactions.label.syncConfirm')}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained" autoFocus>{t('btnNo')}</Button>
          <Button onClick={handleSync} variant="contained" autoFocus>{t('btnYes')}</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openAction} onClose={handleCloseAction}>
        <DialogContent>
          <DialogContentText>
            {t('common.label.email')}
            {' '}
            :
            {' '}
            {customerEmail}
          </DialogContentText>
          <DialogContentText>{t('stripeTransactions.label.actionConfirm')}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAction} variant="contained" autoFocus>{t('btnNo')}</Button>
          <Button onClick={handleAction} variant="contained" autoFocus>{t('btnYes')}</Button>
        </DialogActions>
      </Dialog>
    </SearchForm>
  );
}
