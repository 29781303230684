import { types } from '../actions/setting';

interface SettingRequest {
  type: string;
  payload?: any;
}

type SettingActions = SettingRequest;

interface ISettingState {
  isTrialOn: boolean;
  isSync: boolean;
}

const initialState: ISettingState = {
  isTrialOn: false,
  isSync: false,
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export default (state = initialState, { type, payload }: SettingActions) => {
  switch (type) {
    case types.GET_TRIAL_SETTING_SUCCESS:
      state.isTrialOn = payload.data ?? payload;
      return {
        ...state,
      };
    case types.SAVE_TRIAL_SETTING_SUCCESS:
      state.isTrialOn = payload.data ?? payload;
      return {
        ...state,
      };
    case types.GET_SYNC_SETTING_SUCCESS:
      state.isSync = payload.data ?? payload;
      return {
        ...state,
      };
    default:
      return state;
  }
};
